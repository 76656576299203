import React from "react";
import ReactDOM from "react-dom";
import '../styles/modal.scss';

const Modal = ({ visible, component,className, small, styleDefined = {} }) => visible ? ReactDOM.createPortal(
    <div className="modal">
        <div className={`${small?'modal-content-small': 'modal-content'}`} role="dialog" aria-modal="true" style={styleDefined}>
            <div className={className}>
                {component}
            </div>
        </div>
    </div>, document.body
) : null;

export default Modal;
