import React, {useEffect, useState} from "react";
import {useStateValue} from "../../states/StateProvider";
import {GrClose} from "react-icons/gr";
import PropTypes from "prop-types";
import {ScaleLoader} from "react-spinners";
import Api from "../../Api/api";
import {toast} from "react-toastify";

const CompanyInfoPopUpDGGEdit = ({company, companyName, portal}) => {
    const [{companyInfoEditModal}, dispatch] = useStateValue();
    const [stepsLoading, setStepsLoading] = useState(true);
    const [info, setInfo] = useState(null);

    const firmaKurz = info?.Firma_Kurz
    const adresse = info?.Adresse
    const plz = info?.PLZ
    const stadt = info?.Stadt
    const mitarbeiter = info?.Mitarbeiter
    const telefon = info?.Telefon
    const webseite = info?.Webseite
    const uStId = info?.USt_ID
    const ansprechpartner = info?.Ansprechpartner
    const aspPosition = info?.Asp_Position
    const aspTelefon = info?.Asp_Telefon
    const aspMobil = info?.Asp_Mobil
    const aspEmail = info?.Asp_Email

    useEffect(() => {
        dispatch({type: 'SET_SENDMAIL', item: false});
        setStepsLoading(true);
        Api().get(`/customerDetailsForEdit/${portal}/${company}`).then(res => {
            setInfo(res.data[0]);
            setStepsLoading(false);
        });
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();
        setStepsLoading(true);
        const formData = new FormData(e.target);
        const Data = Object.fromEntries(formData.entries());
        Data.portal = portal
        Data.company = company
        Data.companyName = companyName

        Api().post('/setCustomerDetails', Data).then(res => {
            const response = getFirstItemWithoutKey(res.data[0]);
            if (response !== null) {
                toast.success(response)
            }
            setStepsLoading(false);
            dispatch({
                type: "SET_COMPANYINFO_EDIT_MODAL",
                item: !companyInfoEditModal
            });
        }).catch(e => {
            toast.error('irgendwas ist schief gelaufen!')
            setStepsLoading(false);
        })
    };

    const getFirstItemWithoutKey = (obj) => {
        for (const key in obj) {
            if (!key || key.trim() === '') {
                return obj[key];
            }
        }
        return null; // Return null if no such item is found
    };

    return (
        <div className='px-2 rounded-lg text-sm'>
            {
                stepsLoading ?
                    <div className='centerItemsAbsolute'>
                        <ScaleLoader/>
                    </div>
                    :
                    <form className='px-5' onSubmit={handleSubmit}>
                        <GrClose className='cursor-pointer float-right mr-4'
                                 onClick={() => dispatch({
                                     type: "SET_COMPANYINFO_EDIT_MODAL",
                                     item: !companyInfoEditModal
                                 })}
                                 size='24px'/>
                        <div style={{marginTop: '2rem'}}>
                            <h2 style={{color: '#000000'}}
                                className='font-bold text-xl my-2 w-fi py-1'>Firmen-Stammdaten&ndash;Firmendetails</h2>
                            <p className='flex justify-start items-start mb-3 text-grey text-sm mt-2'>Korrigieren/ergänzen Sie hier die Stammdaten bei Bedarf:</p>
                            <label className='text-grey font-thin my-2 text-sm'>Firma:</label>
                            <input type="text" name="firmaKurz" defaultValue={firmaKurz}
                                   className='w-full mb-2 p-2 border'/>

                            <label className='text-grey font-thin my-2 text-sm'>Anschrift:</label>
                            <input type="text" name="adresse" defaultValue={adresse}
                                   className='w-full mb-2 p-2 border'/>

                            <label className='text-grey font-thin my-2 text-sm'>PLZ:</label>
                            <input type="text" name="plz" defaultValue={plz} className='w-full mb-2 p-2 border'/>

                            <label className='text-grey font-thin my-2 text-sm'>Stadt:</label>
                            <input type="text" name="stadt" defaultValue={stadt} className='w-full mb-2 p-2 border'/>

                            <label className='text-grey font-thin my-2 text-sm'>Mitarbeiter:</label>
                            <input type='number' name="mitarbeiter" defaultValue={mitarbeiter}
                                   className='w-full mb-2 p-2 border'/>

                            <label className='text-grey font-thin my-2 text-sm'>Telefon:</label>
                            <input type="text" name="telefon" defaultValue={telefon}
                                   className='w-full mb-2 p-2 border'/>

                            <label className='text-grey font-thin my-2 text-sm'>Webseite:</label>
                            <input type="text" name="webseite" defaultValue={webseite}
                                   className='w-full mb-2 p-2 border'/>

                            <label className='text-grey font-thin my-2 text-sm'>USt-ID:</label>
                            <input type="text" name="uStId" defaultValue={uStId} className='w-full mb-2 p-2 border'/>

                            <label className='text-grey font-thin my-2 text-sm'>Ansprechpartner:</label>
                            <input type="text" name="ansprechpartner" defaultValue={ansprechpartner}
                                   className='w-full mb-2 p-2 border'/>

                            <label className='text-grey font-thin my-2 text-sm'>Position:</label>
                            <input type="text" name="aspPosition" defaultValue={aspPosition}
                                   className='w-full mb-2 p-2 border'/>

                            <label className='text-grey font-thin my-2 text-sm'>Telefon:</label>
                            <input type="text" name="aspTelefon" defaultValue={aspTelefon}
                                   className='w-full mb-2 p-2 border'/>

                            <label className='text-grey font-thin my-2 text-sm'>Mobil:</label>
                            <input type="text" name="aspMobil" defaultValue={aspMobil}
                                   className='w-full mb-2 p-2 border'/>

                            <label className='text-grey font-thin my-2 text-sm'>Email:</label>
                            <input type="text" name="aspEmail" defaultValue={aspEmail}
                                   className='w-full mb-2 p-2 border'/>

                            <input
                                className={`bg-mainBlue w-full rounded-lg col-span-2 mr-2 px-3 py-2 mt-5 text-white cursor-pointer text-sm`}
                                type="submit"
                                value={`${!stepsLoading ? 'Änderung beantragen' : 'speichere...'}`}
                            />
                        </div>
                    </form>
            }
        </div>
    );
};

export default CompanyInfoPopUpDGGEdit;

CompanyInfoPopUpDGGEdit.propTypes = {
    company: PropTypes.string.isRequired,
    companyName: PropTypes.string.isRequired,
    portal: PropTypes.string.isRequired,
};
