import React from "react";
import {useForm, Controller} from "react-hook-form";
import DatePicker from "react-datepicker";
import {AiFillCloseCircle} from "react-icons/ai";
import {GoCalendar} from "react-icons/go";

const ModalWiedervorlage = ({
                                toggleRemindersModal,
                                remindersModal,
                                options,
                                userID,
                                id,
                                portal,
                                role,
                                dispatch,
                                onSubmit,
                                loading,
                                watch,
                                isValid,
                                errors,
                                control,
                                register,
                                getValues,
                                datePickerRef2,
                                convertLocalToUTCDate,
                                cancelEditStates
                            }) => {
    return (<div>
            <h2 className='flex justify-start items-start ml-20 text-grey text-xl'
                style={{marginTop: '2rem'}}>Wiedervorlage / Nächster Schritt</h2>
            <p className='flex justify-start items-start ml-20 text-grey text-sm mt-2'>* Pflichtfelder</p>
            <p style={{float: 'right', cursor: 'pointer', marginTop: "-9vh"}}
               onClick={() => dispatch({type: "SET_REMINDERS_MODAL", item: !remindersModal})}>
                <AiFillCloseCircle size='35px' color={'#232323'}/>
            </p>
            <form onSubmit={onSubmit} style={{marginTop: '-8vh'}}
                  className='centerItemsAbsolute grid grid-cols-2 gap-2'>
                <section className='col-span-2'>
                    <select {...register('rmTitle', {required: true})}
                            className={`w-full p-3 md:w-full cursor-pointer bg-white border border-whiteDark rounded-md subStepSelect`}
                    >
                        <option hidden>
                            Wähle eine Option *
                        </option>
                        {options?.filter(o => o.rmTitle !== null).map((op, index) => (
                            <option key={index}>{op.rmTitle}</option>))}
                    </select>
                </section>

                <section className='col-span-2'>
                    <textarea
                        rows={5}
                        className={`w-full p-3 md:w-full bg-white border border-whiteDark rounded-md subStepSelect`}
                        placeholder='zusätzliche Bermerkung'
                        {...register('rmText', {required: false})}
                    />
                </section>

                <section className='col-span-1'>
                    <input hidden {...register('uID')} value={userID}/>
                    <input hidden {...register('fpID')} value={id}/>
                    <h3 className='text-grey font-thin my-2 text-sm'>Wiedervorlage Datum: *</h3>
                    <Controller
                        control={control}
                        name='date'
                        render={({field}) => (<div ref={datePickerRef2}
                                                   className="flex justify-between items-center border border-1 border-whiteDark">
                                <DatePicker
                                    closeOnScroll={true}
                                    locale="de" dateFormat="P" showYearDropdown
                                    placeholderText={`Datum wählen`}
                                    onChange={(date) => field.onChange(convertLocalToUTCDate(date))}
                                    selected={field.value}
                                    cssClass={'datePicker'}
                                    isClearable
                                    className={'border-none'}
                                    readOnly={(role === 'ManRUV' || role === 'ManDGG')}
                                />
                                <div
                                    className={`absolute ${getValues('date') && 'mr-6'} md:right-60 sm:right-48`}
                                    style={{pointerEvents: 'none'}}>
                                    <GoCalendar color={'#4d57a8'} size={'18px'}/>
                                </div>
                            </div>)}
                    />
                </section>

                <section className='col-span-1'>

                </section>
                <section className='col-span-1'>
                    <h3 className='text-grey font-thin my-2 text-sm'>Benachrichtgungen an: *</h3>
                    <input
                        className={`p-3 bg-white border border-whiteDark rounded-md subStepSelect`}
                        placeholder='Email an: '
                        type='email'
                        {...register('uEmail', {
                            required: 'Email is required', pattern: {
                                value: /^\S+@\S+\.\S+$/, message: 'Please enter a valid email address',
                            }
                        })}
                        style={{border: (!watch('uEmail')) && '1px solid red'}}
                    />
                </section>

                <section className='col-span-1'>

                </section>

                <input
                    className={`w-full p-3 md:w-full bg-white border border-whiteDark rounded-md subStepSelect`}
                    placeholder='Email ebenfalls an:'
                    {...register('uEmail2', {
                        required: false, pattern: {
                            value: /^\S+@\S+\.\S+$/, message: 'Please enter a valid email address',
                        }
                    })}
                    style={{border: errors.uEmail2 && '1px solid red'}}
                />
                <input
                    className={`w-full p-3 md:w-full bg-white border border-whiteDark rounded-md subStepSelect`}
                    placeholder='Email ebenfalls an:'
                    {...register('uEmail3', {
                        required: false, pattern: {
                            value: /^\S+@\S+\.\S+$/, message: 'Please enter a valid email address',
                        }
                    })}
                    style={{border: errors.uEmail3 && '1px solid red'}}
                />

                <section className='flex justify-start text-grey items-center gap-2'>
                    <input
                        type='checkbox'
                        className={`cursor-pointer`}
                        placeholder='Mail an DG AMS'
                        {...register('rmMailAMS', {required: false})}
                    />
                    {portal === 'ruv' ? <label>Mail an DGAPI AMS</label> : <label>Mail an DG AMS</label>}
                </section>

                <section className='flex justify-start text-grey items-center gap-2'>
                    <input
                        type='checkbox'
                        className={`cursor-pointer`}
                        placeholder='Mail an DG KAM'
                        {...register('rmMailKAM', {required: false})}

                    />
                    {portal === 'ruv' ? <label>Mail an DGAPI KAM</label> : <label>Mail an DG KAM</label>}
                </section>
                <section className='flex justify-center text-grey items-center gap-2 col-span-2 text-sm'>
                    <input
                        type='checkbox'
                        className={`cursor-pointer`}
                        {...register('pauseICIM', {required: false})}

                    />
                    <label>infomail in der Zwischenzeit pausieren</label>
                </section>
                <section className='col-span-2'>
                    <input
                        className={`bg-mainBlue w-56 rounded-lg col-span-2 mr-2 px-3 py-2 mt-5 text-white cursor-pointer text-sm ${(!watch('date')) || !isValid || watch('rmTitle') === 'Wähle eine Option *' ? 'bg-disableBlue cursor-no-drop' : 'bg-mainBlue hover:bg-lightBlue'}`}
                        type="submit"
                        disabled={(!watch('date')) || watch('rmTitle') === 'Wähle eine Option *' || !isValid || (role === 'ManRUV' || role === 'ManDGG')}
                        value={`${!loading ? 'Speichern' : 'speichere...'}`}
                    />

                    <input
                        className={`bg-grey w-56 hover:bg-cancel col-span-2 rounded-lg px-3 py-2 mt-2 cursor-pointer text-white text-sm text-center`}
                        value={`abbrechen`}
                        onClick={cancelEditStates}
                    />
                </section>
            </form>
        </div>);
};

export default ModalWiedervorlage;
