import React, {useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import Api from "../Api/api";
import {useStateValue} from "../states/StateProvider";
import {CgCopyright} from "react-icons/cg";
import {AiFillCopyrightCircle, AiOutlineCopyrightCircle} from "react-icons/ai";
import {BeatLoader} from "react-spinners";

const Footer = () => {
    const location = useLocation()

    const [loading, setLoading] = useState(false)
    const [errorText, setErrorText] = useState('')
    const [{footerUpdated, portal}] = useStateValue();

    useEffect(() => {
        setLoading(true)
        Api().get(`sp_getFooterString/${portal}`).then(res => {
            setErrorText(res.data[0]?.string)
        }).then(res=>{
            setLoading(false)
        }).catch(e=>{
            setLoading(false)
        })

    }, [footerUpdated, portal]);

    return (
        <div className='flex bg-white justify-between px-5 pt-3'
             style={{display: location.pathname.includes('anmeldung') || location.pathname.includes('registrieren') || location.pathname.includes('reset-password') ? 'none' : ''}}>
            <h1>
                <span className='flex justify-start items-center'>
                    <AiOutlineCopyrightCircle size='19px'/>
                    <span className='ml-1'> Helmsauer Gruppe</span>
                </span>
                <p className='text-left text-xs'>FE V3.7.0</p>
            </h1>
            {loading && <BeatLoader size={'8px'}/>}
            {errorText && !loading && <p className='text-red'>{errorText}</p>}
            <div className='flex justify-end text-sm'>
                {
                    portal==='ruv'?
                        <a href='https://www.dg-gruppe.eu/public/download/pp/1/PP-Anleitung-RuV.pdf' target='_blank'
                           className='pr-5 text-grey cursor-pointer text-sm'>
                            Bedienungsanleitung
                        </a>
                        :
                        <a href='https://www.dg-gruppe.eu/public/download/pp/2/PP-Anleitung-DGG.pdf' target='_blank'
                           className='pr-5 text-grey cursor-pointer text-sm'>
                            Bedienungsanleitung
                        </a>
                }

            </div>
        </div>
    )
}
export default Footer
