import React, {useEffect, useRef, useState} from "react";
import {Controller, useForm} from "react-hook-form";
import DatePicker from "react-datepicker";
import Api from "../../../Api/api";
import {toast} from "react-toastify";
import {formatDate} from "../../../helper/formatDate";
import {AiFillCloseCircle, AiOutlineDelete, AiOutlineEdit} from "react-icons/ai";
import {SkewLoader} from "react-spinners";
import useModal from "../../../hooks/useModal";
import {useStateValue} from "../../../states/StateProvider";
import {GoCalendar} from "react-icons/go";
import {AES, enc} from "crypto-js";
import Modal from "../../../hooks/modal";
import ModalWiedervorlage from "../../../hooks/modalWidervorlage";

const Reminders = ({id, userID, role, portal}) => {

    const [loading, setLoading] = useState(false)
    const [loadingData, setLoadingData] = useState(false)
    const [editing, setEditing] = useState(false)
    const [updated, setUpdated] = useState(0)
    const [options, setOptions] = useState([])
    const [author, setAuthor] = useState([])
    const [exists, setExists] = useState('0')
    const {toggleRemindersModal} = useModal();
    const [{remindersModal, remindersSaved, secretKey}, dispatch] = useStateValue();
    const decryptedBytes = localStorage.getItem('user') ? AES.decrypt(localStorage.getItem('user'), secretKey) : false;
    const user = JSON.parse(decryptedBytes.toString(enc.Utf8))
    const datePickerRef2 = useRef(null);

    const {
        register,
        getValues,
        setValue,
        watch,
        handleSubmit,
        formState,
        reset,
        formState: {errors, touchedFields, isValid},
        control
    } = useForm({mode: "onChange"});

    function convertLocalToUTCDate(date) {
        if (!date) {
            return date
        }
        date = new Date(date)
        date = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()))
        return date
    }

    useEffect(() => {
        setLoadingData(true)
        Api().get(`/reminderOptions/${portal}/${id}`).then(res => {
            setOptions(res.data.options)
            setAuthor(res.data.author)
            setExists(res.data.exists)
            setLoadingData(false)
        })
    }, [updated]);

    const onSubmit = (Data) => {
        setLoading(true)
        Data.portal = portal

        Api().post('/saveReminders', Data).then(res => {
            toast.success('Erinnerung erfolgreich gespeichert')
            setLoading(false)
            setUpdated(updated + 1)
            setEditing(false)
            dispatch({type: "SET_REMINDERS_MODAL", item: !remindersModal})
            dispatch({type: "SET_REMINDERS_SAVED", item: !remindersSaved})
        }).catch(e => {
            toast.error('irgendwas ist schief gelaufen!')
            setLoading(false)
        })
    };

    function deleteReminder() {
        confirm('Diese Erinnerung löschen?') && Api().get(`/deleteReminders/${portal}/${id}`).then(res => {
            setUpdated(updated + 1)
            toast.success('Erfolgreich gelöscht')
            setValue('rmTitle', 'Wähle eine Option *')
            reset()
        }).catch(e => {
            toast.error('Etwas ist schief gelaufen!')
            setUpdated(updated + 1)
        })
    }

    function setEditStates() {
        setEditing(true)
        setValue('rmTitle', author[0].rmTitle)
        setValue('rmText', author[0].rmText)
        setValue('uEmail', author[0].rmMail1)
        setValue('uEmail2', author[0].rmMail2)
        setValue('uEmail3', author[0].rmMail3)
        setValue('rmMailAMS', author[0].rmMailAMS === '1')
        setValue('rmMailKAM', author[0].rmMailKAM === '1')
        setValue('pauseICIM', author[0].pauseICIM === '1')
        setValue('date', convertLocalToUTCDate(author[0].rmDate))
        dispatch({type: "SET_REMINDERS_MODAL", item: !remindersModal})
    }

    function setInitialStates() {
        toggleRemindersModal()
        setValue('uEmail', user.email)
    }

    function cancelEditStates() {
        setEditing(false)
        dispatch({type: "SET_REMINDERS_MODAL", item: !remindersModal})
    }

    const style = {
        height: '73vh',
    };
    return (<div className='text-left px-2 pt-5 pb-2'>
        {loadingData ? <div style={{minHeight: '150px'}}>
            <SkewLoader size={10} color={'grey'}/>
        </div> : <div className='border border-b-1 border-b-offWhite border-x-0 border-t-0'>
            {exists === '0' ? <button
                className={`${role === 'Controller' && 'hidden'} px-3 py-2 my-4 hover:bg-lightBlue rounded-3xl bg-mainBlue text-white text-sm`}
                onClick={setInitialStates}
            >
                Neue Wiedervorlage
            </button> : <div>
                <h2 className='text-xl mb-2 font-bold'>Wiedervorlage</h2>
                <div className='flex items-center justify-between px-2 pt-2 pb-5'>
                    <div className='text-sm text-grey'>
                        <p>Wiedervorlage von: {author[0].rmUser}</p>
                        <p>Wiedervorlage am: {formatDate(author[0].rmDate, false)}</p>
                        <p>Grund: {author[0].rmTitle}</p>
                    </div>
                    <div className='flex justify-between flex-wrap'>
                        <a title='bearbeiten' onClick={setEditStates}
                           className='cursor-pointer'>
                            <AiOutlineEdit color={'#1c3aa1'} size={'19px'}/>
                        </a>
                        <a title='löschen' onClick={deleteReminder} className='cursor-pointer'>
                            <AiOutlineDelete color={'#987474'} size={'19px'}/>
                        </a>
                    </div>
                </div>
            </div>}
        </div>}

        <Modal toggle={toggleRemindersModal}
               visible={remindersModal}
               styleDefined={style}
               component={<ModalWiedervorlage
                   toggleRemindersModal={toggleRemindersModal}
                   remindersModal={remindersModal}
                   options={options}
                   userID={userID}
                   id={id}
                   portal={portal}
                   role={role}
                   dispatch={dispatch}
                   onSubmit={handleSubmit(onSubmit)}
                   loading={loading}
                   watch={watch}
                   isValid={isValid}
                   errors={errors}
                   control={control}
                   register={register}
                   getValues={getValues}
                   datePickerRef2={datePickerRef2}
                   convertLocalToUTCDate={convertLocalToUTCDate}
                   cancelEditStates={cancelEditStates}
               />}
        />
    </div>)
}

export default Reminders
